import React, { FC } from 'react';
import './appHero.css';
import heroImg from '../../images/hero-img.jpg';

interface appHeroProps { }

const AppHero: FC<appHeroProps> = () => {

  return (
    <section className="bg-white">
    <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="mr-auto place-self-center text-left ml-6 lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-blue"> Secure Your Property in Ghana Without the Hassle</h1>
            <p className="max-w-2xl mb-6 font-light text-blue lg:mb-8 md:text-lg lg:text-xl">We help Ghanaians in the diaspora build homes and purchase lands with full transparency</p>
            <a href="#signup" className="inline-flex items-center justify-center mb-4 px-5 py-3 mr-3 text-base font-medium text-center text-blue rounded-lg bg-green hover:bg-blue hover:text-green focus:ring-4 focus:ring-primary-300">
            Sign up for Early Access
                <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </a>
            <a href="#our-solution" className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-blue border border-blue rounded-lg hover:bg-blue hover:text-white focus:ring-4 focus:ring-gray-100">
                Learn more
            </a> 
        </div>
        <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src={heroImg} alt="mockup" />
        </div>                
    </div>
</section>
  );

}

export default AppHero;
