import React, { FC } from 'react';
import './teamSection.css';
import SectionTitle from '../sectionTitle/sectionTitle';
import hannah from '../../images/team/hannah.png';
import sefakor from '../../images/team/sefakor.png';
import afua from '../../images/team/afua.png';
import bwood from '../../images/team/bwood.png';
import sedem from '../../images/team/sedem.png';

interface TeamCardPropsType {
  img: string;
  name: string;
  title: string;
  linkedin: string;
}

function TeamCard({ img, name, title, linkedin }: TeamCardPropsType) {
  return (
    <div className="text-center">
      <img className="mx-auto mb-4 w-36 h-36 rounded-full hover:border-solid hover:border-blue hover:border-2" src={img} alt={name} />
      <h3 className="mb-1 text-2xl font-bold tracking-tight text-blue">
        <a href={linkedin} target="_blank" rel="noreferrer">{name}</a>
      </h3>
      <p className='text-blue xl'>{title}</p>
      <ul className="flex justify-center mt-4 space-x-4">
        <li>
          <a href={linkedin} target="_blank" rel="noreferrer" className="text-[#ea4c89] hover:text-grey-400">
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path fill-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clip-rule="evenodd" /></svg>
          </a>
        </li> 
      </ul>
    </div>
  );
}


const members = [
  {
    img: hannah,
    name: "Hannah Lormenyo",
    title: "CEO & Founder",
    linkedin: "https://www.linkedin.com/in/hannah-lormenyo/",
  },
  {
    img: bwood,
    name: "Bryan Wood",
    title: "Financial Analyst",
    linkedin: "https://www.linkedin.com/in/bryaneffahwood/",
  },
  {
    img: sedem,
    name: "Sedem Amekpewu",
    title: "Software Engineer",
    linkedin: "https://www.linkedin.com/in/sedemquame/",
  },
  {
    img: sefakor,
    name: "Sefakor Kwamuar",
    title: "Lawyer",
    linkedin: "https://www.linkedin.com/in/sefakor-k-90100b158/",
  },
  {
    img: afua,
    name: "Afua Yiadom",
    title: "Lawyer",
    linkedin: "https://www.linkedin.com/in/afua-adutwumwaa-adarkwa-yiadom-b98730200/",
  },

];



interface teamSectionProps { }

const TeamSection: FC<teamSectionProps> = () => (
  <div className="teamSection" data-testid="teamSection" id='our-team'>

    <SectionTitle title='Meet the Team' subtitle='Our Team of Professionals' />

    <div className="container mx-auto mt-20">

      <div className="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
        {members.map((props, key) => (
          <TeamCard key={key} {...props} />
        ))}
      </div>
    </div>
  </div>
);

export default TeamSection;
