import React, { FC } from 'react';
import './pillarCard.css';
import { motion } from "framer-motion";

interface pillarCardProps { index: string, title: string, subtitle: string, background: string }

const pillarItem = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};

const pillars = [
  {index: "01", title: "Verified Property Listings", subtitle:"We work with trusted agents to provide you with verified land and home options", background:"./images/bubble-pattern.png"},
  {index: "02", title: "Secure Escrow Service", subtitle:"Your money is only released once project milestones are completed.", background:"./images/bubble-pattern.svg"},
  {index: "03", title: "Project Management", subtitle:"Real-time updates and progress reports directly to your dashboard or mobile app.", background:"./images/bubble-pattern.svg"},
  {index: "04", title: "Legal Support", subtitle:"All transactions are protected with verified contracts and legal oversight.", background:"./images/bubble-pattern.svg"},
]

const PillarCard: FC<pillarCardProps> = ({index, title, subtitle, background}) => {
  let cardStyle = "bg-[url(./images/bubble-pattern.png)] bg-no-repeat bg-cover bg-grey-400 text-white hover:bg-green hover:text-black h-96 w-72 rounded-xl text-left py-10 px-5";
  let firstCardStyle = "bg-[url(./images/bubble-pattern.png)] bg-no-repeat bg-cover bg-green text-black h-96 w-72 rounded-xl text-left py-10 px-5";
  return (
    <div className="pillarCard" data-testid="pillarCard">
      <div className={index === "01"? firstCardStyle :cardStyle}>
        <h1 className='text-3xl font-bold'>{index}</h1>
        <h1 className='w-3/4 text-2xl font-medium mt-20'>{title}</h1>
        <p className='mt-4'>{subtitle}</p>
      </div>
    </div>
  );
}

export const PillarCards = () =>{
  return pillars.map((pillar)=> <motion.div variants={pillarItem}>
    <PillarCard  key={pillar.index} index={pillar.index} title={pillar.title} subtitle={pillar.subtitle} background={pillar.background} />
  </motion.div>);  
}

export default PillarCard;
