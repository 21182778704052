import React, { FC, useRef } from 'react';
import './riskyInvestment.css';
import SectionTitle from '../sectionTitle/sectionTitle';
import { BottomWave, Pie, TopWave } from '../utilComponents/utilComponents';
import { motion, useInView } from 'framer-motion';

interface riskyInvestmentProps { }

const RiskyInvestment: FC<riskyInvestmentProps> = () => {
  const ref = useRef(null)
  const isInView = useInView(ref, {
    amount: "all"
  });

  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  return (
    <div className="riskyInvestment" data-testid="riskyInvestment" id='our-solution'>

      <TopWave />
      <div className='bg-green-50 py-10'>
        <SectionTitle title='Why Investing in Property from Abroad Can Be Risky' subtitle='Thousands of Ghanaians in the diaspora send money to Ghana for
 land purchases and house construction, but many fall victim to fraud,
delays, and poor project management. Without reliable oversight, 
the risk of scams and mismanagement is high' />
        <div className='lg:flex w-4/5 mx-auto mt-4 items-center justify-center'>
          <motion.div
            variants={variants}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            className="flex items-center justify-center mx-auto lg:m-0" ref={ref}>
            <Pie percentage={70} color="#02dba5" />
          </motion.div>
          <div className='block lg:flex lg:items-center lg:justify-center ml-2'>
            <p className='max-w-2xl mb-6 font-medium text-blue text-left text-xl lg:mb-8 md:text-2xl lg:text-3xl'>of diaspora investors report losing money in real estate fraud
              or mismanaged projects.</p>
          </div>
        </div>
      </div>

      <BottomWave />
    </div>
  );
}
export default RiskyInvestment;
