import React, { FC, useState } from 'react';
import './header.css';
import logo from '../../images/logo.png'

interface headerProps { }

const Header: FC<headerProps> = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navLinkStyle = "font-bold text-blue px-3 py-2 rounded-md text-sm font-medium " +
                      "hover:before:scale-x-100 hover:before:origin-left relative " +
                      "before:w-full before:h-1 before:origin-right before:transition-transform " +
                       "before:duration-300 before:scale-x-0 before:bg-green before:absolute " +
                       "before:left-0 before:bottom-0"
  return (
    <div>
      <nav>
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            {/* Logo */}
            <div className="flex-shrink-0">
              <img src={logo} alt="" className='h-8 w-auto' />
            </div>

            {/* Desktop Links - Aligned Right */}
            <div className="hidden sm:block">
              <div className="ml-auto flex space-x-4">
                <a
                  href="#home"
                  className={navLinkStyle}>
                  Home
                </a>
                <a
                  href="#our-solution"
                  className={navLinkStyle}
                >
                  Our Solution
                </a>
                <a
                  href="#how-it-works"
                  className={navLinkStyle}
                >
                  How it works
                </a>
                <a
                  href="#our-team"
                  className={navLinkStyle}
                >
                  Our Team
                </a>
                <a
                  href="#contact"
                  className={navLinkStyle}
                >
                  Contact us
                </a>

                {/* "Sign up for Early Access" Button */}
                <a
                  href="#signup"
                  className="bg-green hover:bg-blue hover:text-green  text-blue font-medium py-2 px-4 rounded-md text-sm"
                >
                  Sign up for Early Access
                </a>
              </div>
            </div>

            {/* Mobile Menu Button */}
            <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-blue hover:bg-green focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                onClick={toggleMenu}
              >
                <span className="sr-only">Open main menu</span>
                {/* Hamburger Icon */}
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16m-7 6h7"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <div className="sm:hidden" id="mobile-menu">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <a
                href="#home"
                className="font-bold text-blue hover:bg-green hover:text-blue block px-3 py-2 rounded-md text-base font-medium"
              >
                Home
              </a>
              <a
                href="#our-solution"
                className="font-bold text-blue hover:bg-green hover:text-blue block px-3 py-2 rounded-md text-base font-medium"
              >
                Our Solution
              </a>
              <a
                href="#how-it-works"
                className="font-bold text-blue hover:bg-green hover:text-blue block px-3 py-2 rounded-md text-base font-medium"
              >
                How it works
              </a>
              <a
                href="#our-team"
                className="font-bold text-blue hover:bg-green hover:text-blue block px-3 py-2 rounded-md text-base font-medium"
              >
                Our Team
              </a>
              <a
                href="#contact"
                className="font-bold text-blue hover:bg-green hover:text-blue block px-3 py-2 rounded-md text-base font-medium"
              >
                Contact us
              </a>

              {/* Mobile View "Sign up for Early Access" Button */}
              <a
                href="#signup"
                className="bg-green text-base text-blue font-medium hover:bg-blue hover:text-green  block py-2 px-4 rounded-md"
              >
                Sign up for Early Access
              </a>
            </div>
          </div>
        )}
      </nav>
    </div>);
}

export default Header;
