import React, { FC } from 'react';
import './freqAskedQuestions.css';
import SectionTitle from '../sectionTitle/sectionTitle';
import FaqAccordion from '../faqAccordion/faqAccordion';

interface freqAskedQuestionsProps { }

const FreqAskedQuestions: FC<freqAskedQuestionsProps> = () => {
  const faqs = [
    {
      title: 'How do I know the properties are verified?',
      content:
        'We vet all listings through a thorough due diligence process to ensure legitimacy',
    },
    {
      title: 'What is an escrow service?',
      content:
        'Escrow holds your funds securely and only releases them when agreed-upon milestones are met.',
    },
    {
      title: 'How do I track the progress of my project?',
      content:
        'You will receive real-time updates, photos, and reports via our project management dashboard',
    },
  ];

  return (
    <div className="freqAskedQuestions" data-testid="freqAskedQuestions">
      <SectionTitle title='Frequently Asked Questions (FAQs)' subtitle='We are here to help with all your questions and answers in one place' />

      <section className="py-24">


        {faqs.map((faq, index) => (
          <FaqAccordion key={index} question={faq.title} answer={faq.content} />
        ))}

      </section>

    </div>
  );
}

export default FreqAskedQuestions;
