import './App.css';
import AppHero from './components/appHero/appHero';
import Footer from './components/footer/footer';
import FreqAskedQuestions from './components/freqAskedQuestions/freqAskedQuestions';
import Header from './components/header/header';
import HowItWorks from './components/howItWorks/howItWorks';
import RiskyInvestment from './components/riskyInvestment/riskyInvestment';
import SignUpForEarlyAccess from './components/signUpForEarlyAccess/signUpForEarlyAccess';
import Solution from './components/solution/solution';
import TeamSection from './components/teamSection/teamSection';


function App() {
  return (
    <div className="App">
      <Header />
      <AppHero />
      <RiskyInvestment />
      <Solution />
      <HowItWorks />
      <SignUpForEarlyAccess />
      <TeamSection />
      <FreqAskedQuestions />
      <Footer />
    </div>
  );
}

export default App;
