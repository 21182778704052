import React, { BaseSyntheticEvent, FC, useRef, useState } from 'react';
import './signUpForEarlyAccess.css';
import SectionTitle from '../sectionTitle/sectionTitle';
import contact from '../../images/contact.jpg'
import CountrySelector from '../countrySelector/countrySelector';
import { BottomWave, LoadingModal, Modal, TopWave } from '../utilComponents/utilComponents';
import Select from 'react-select'


const URL = process.env.REACT_APP_SCRIPT_URL;

interface signUpForEarlyAccessProps { }


const SignUpForEarlyAccess: FC<signUpForEarlyAccessProps> = () => {

  const formRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const handleSubmit = (e: BaseSyntheticEvent) => {
    setSpinner(true);


    e.preventDefault();
    fetch(`${URL}`, {
      method: 'POST',
      body: new FormData(e.target),
    }).then(res => {
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      return res.json();
    })
      .then(data => {
        setSpinner(false);
        setModal(true);
        console.log(data);

        // alert(data.msg);
      })
      .catch(err => {
        console.error('There was a problem with the fetch operation:', err);
        alert('Submission failed. Please try again.');
      });
  };

  const closeModal = () => {
    setModal(false);
    window.location.reload();
  }

  const options = [
    { value: 'Buy Land', label: 'Buy Land' },
    { value: 'Buy House', label: 'Buy House' },
    { value: 'Buy Land and Build House', label: 'Buy Land and Build House' }
  ];


  return (
    <div className="signUpForEarlyAccess" data-testid="signUpForEarlyAccess" id='signup'>

      <LoadingModal openModal={spinner} />
      
      <Modal
        openModal={modal}
        closeModal={closeModal}>
        <div className='px-16 py-6'>
          <p>Thank you for registering your interest!</p>
          <p>We will reach out to you as soon as the big Reveal is live.</p>
        </div>

      </Modal>

      <TopWave />
      <div className='bg-green-50 pb-10 pt-1'>
        <SectionTitle title='Ready to Secure Your Property Investment' subtitle='Join hundreds of Ghanaians abroad already investing safely in real estate back home. Sign up today for exclusive early access to our platform.' />

        <div className='flex flex-col md:flex-row items-center md:items-end mb-10 w-4/5 gap-4 mx-auto justify-center bg-white py-10 shadow rounded-lg border border-grey-50'>

          {/* contact form */}
          <div className="flex-1 max-w-sm p-4 bg-white border border-grey-50 rounded-lg shadow sm:p-6 md:p-8 text-left">
            <form className="space-y-6" action="#" ref={formRef} onSubmit={handleSubmit}>

              <div>
                <label htmlFor="fullName" className="block mb-2 text-sm font-medium text-grey-800">Your Full Name</label>
                <input type="text" name="FullName" id="fullName" className="bg-white border border-grey-50 text-grey-800 text-sm rounded-lg focus:ring-blue focus:border-blue block w-full p-2.5" placeholder="Ama Doe" required />
              </div>

              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-grey-800">Your email</label>
                <input type="email" name="Email" id="email" className="bg-white border border-grey-50 text-grey-800 text-sm rounded-lg focus:ring-blue focus:border-blue block w-full p-2.5" placeholder="name@email.com" required />
              </div>

              <div>
                <label className="block mb-2 text-sm font-medium text-grey-800">Your Current Country of Residence</label>
                <CountrySelector name="CountryResidence" />
              </div>

              <div>
                <label htmlFor="investmentInterest" className="block mb-2 text-sm font-medium text-grey-800">Your Investment Interest</label>
                <Select options={options} name="InvestmentInterest" placeholder="Buy House" />
              </div>

              <button type="submit" className="w-full text-black bg-green hover:bg-blue hover:text-green focus:ring-4 focus:outline-none focus:ring-blue font-medium rounded-lg text-sm px-5 py-2.5 text-center">Sign Up for Early Access</button>

            </form>
          </div>

          {/* image */}
          <div className='hidden items-center lg:flex lg:items-center'>
            <img src={contact} alt="" className='h-96' />
          </div>

        </div>
      </div>
      <BottomWave />
    </div>
  );
}

export default SignUpForEarlyAccess;
