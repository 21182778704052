import React, { FC, useEffect, useState } from 'react';
import './countrySelector.css';
import Select, { SingleValue } from 'react-select';

interface countrySelectorProps {name:string }

const CountrySelector: FC<countrySelectorProps> = ({name}) => {

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState<SingleValue<{}>>({});

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        setSelectedCountry(data.userSelectValue);
      });
  }, []);
  return (
    <div className="countrySelector" data-testid="countrySelector">
      <Select
      name={name}
        options={countries}
        value={selectedCountry}
        onChange={(selectedOption) => setSelectedCountry(selectedOption)}
      />
    </div>
  );
}
export default CountrySelector;
