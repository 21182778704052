import React, { FC, useState } from 'react';
import './faqAccordion.css';

interface faqAccordionProps { question: string, answer: string }

const FaqAccordion: FC<faqAccordionProps> = ({ question, answer }) => {


  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 text-left">
      <div className="accordion-group" data-accordion="default-accordion">
        <div
          className="accordion border border-solid border-grey p-4 rounded-xl transition duration-500 hover:bg-green-50 hover:text-blue accordion-active:bg-green accordion-active:border-green mb-8 lg:p-4 active"
          id="basic-heading-one-with-icon">
          <button
            onClick={toggleAccordion}
            className="accordion-toggle group inline-flex items-center justify-between text-left text-lg font-normal leading-8 text-blue w-full transition duration-500 hover:font-bold accordion-active:font-medium accordion-active:text-green"
            aria-controls="basic-collapse-one-with-icon">
            <h5>{question}</h5>
            {isOpen ? <svg
              className="w-6 h-6 text-grey-800 transition-all duration-500 accordion-active:text-blue accordion-active:font-bold accordion-active:block group-hover:text-blue"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6 12H18"
                stroke="currentColor"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg> : <svg
              className="w-6 h-6 text-grey-800 transition-all duration-500 block accordion-active:text-black accordion-active:font-bold accordion-active:hidden group-hover:text-black group-hover:font-bold origin-center"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6 12H18M12 18V6"
                stroke="currentColor"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>

            }
          </button>
          <div
            id="basic-collapse-one-with-icon"
            className="accordion-content w-full overflow-hidden pr-4"
            aria-labelledby="basic-heading-one"
          // style="max-height: 250px;" 
          >
            {isOpen && <p className="text-base text-grey-800 font-normal leading-6">
              {answer}
            </p>
            }
          </div>
        </div>

      </div>
    </div>
  );
}



export default FaqAccordion;
