import React, { FC, useRef } from 'react';
import './solution.css';
import SectionTitle from '../sectionTitle/sectionTitle';
import { PillarCards } from '../pillarCard/pillarCard';
import { motion, useInView, } from "framer-motion";

interface solutionProps { }



const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
};

const Solution: FC<solutionProps> = () => {
  const ref = useRef(null)
  const isInView = useInView(ref, {
    amount: "some"
  })

  return (
  <div className="solution" data-testid="solution" ref={ref}>
    <SectionTitle title='How We Solve This Problem' subtitle='Our Solution: Safe, Transparent, and Accountable Real Estate Services' />
    <div className='container w-4/5 mx-auto mt-8 text-left'>
      <h3 className='font-bold text-xl'>Our Mission:</h3>
      <p>Reveal’s mission depends on these 4 pillars</p>
    </div>
    <motion.div
      variants={container}
      initial="hidden"
      animate={ isInView ? "visible" : "hidden"}
      className='container w-10/12 mx-auto mt-8 gap-4 flex flex-wrap items-center justify-center lg:justify-start'>
      {
        PillarCards()
      }
    </motion.div>
  </div>
);
}

export default Solution;
