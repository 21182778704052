import React, { FC } from 'react';
import './sectionTitle.css';

interface sectionTitleProps {
  title:string,
  subtitle:string
}

const SectionTitle: FC<sectionTitleProps> = ({title, subtitle}) => (
  <div className="sectionTitle mt-40" data-testid="sectionTitle">
    <div className='w-3/5 mx-auto'>
      <h1 className='mx-auto max-w-2xl mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl xl:text-5xl text-blue'>{title}</h1>
      <p className='mx-auto max-w-2xl mb-6 font-light text-blue lg:mb-8 md:text-lg lg:text-xl'>{subtitle}</p>
    </div>
  </div>
);

export default SectionTitle;
