import React, { FC } from 'react';
import './howItWorks.css';
import SectionTitle from '../sectionTitle/sectionTitle';
import verifiedListings from '../../images/verified-listings.jpg';
import trackProgress from '../../images/track-progress.jpg';
import vault from '../../images/vault.png';
import { motion, useInView } from 'framer-motion';


interface howItWorksProps { index: string, title: string, subtitle: string, image: string, isReverse: boolean }

const HowItWorksStep: FC<howItWorksProps> = ({ index, title, subtitle, image, isReverse }) => {

  if (isReverse) {
    return (
      <div
        className='flex flex-col md:flex-row items-center md:items-end mb-4 w-full justify-end hover:border-r-4 hover:border-green-50'>
        <div className='flex-1'>
          <img src={image} alt="" className='h-60 float-left' />
        </div>
        <div className='flex-1 text-right border-r-4 border-green pr-10 pb-4 h-60 lg:w-3/5'>
          <h1 className='text-5xl text-green font-bold mt-4 h-1/5'>{index}</h1>
          <h3 className='text-xl font-medium mt-16'>{title}</h3>
          <p className='mt-4' >{subtitle}</p>
        </div>

      </div>);
  }
  else {
    return (
      <div
        className='flex flex-col md:flex-row gap-4 items-center md:items-start mb-4 hover:border-l-4 hover:border-green-50'>
        <div className='flex-1 text-left border-l-4 border-green pl-10 pb-4 h-60'>
          <h1 className='text-5xl text-green font-bold mt-4'>{index}</h1>
          <h3 className='text-xl font-medium mt-16'>{title}</h3>
          <p className='mt-4'>{subtitle}</p>
        </div>
        {/* show image last on medium screen sizes and above */}
        <div className='flex-1 order-first md:order-last'>
          <img src={image} alt="" className='h-60 float-right' />
        </div>
      </div>);
  }
}

const HowItWorks = () => {

  const ref = React.useRef(null);

  const isInView = useInView(ref, {
    amount: "some"
  });

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const variants = {
    hidden: { x: 40, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1
    }
  };


  return (
    <div className="howItWorks" data-testid="howItWorks" id='how-it-works'>
      <SectionTitle title='How It Works' subtitle='Here is How It Works in 3 Easy Steps' />

      <motion.div
        ref={ref}
        variants={container}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        className='max-w-5xl mx-auto py-12 px-4'>

        {/* TODO: use loop for this */}
        <motion.div variants={variants}>
          <HowItWorksStep index='01' title='Browse Verified Listings' subtitle='Select from a range of verified lands or homes through our platform' image={verifiedListings} isReverse={false} />
        </motion.div>

        <motion.div variants={variants}>
          <HowItWorksStep index='02' title='Secure Your Investment' subtitle='Make payments safely using our escrow service. Funds are only released based on project milestones.' image={vault} isReverse={true} />
        </motion.div>

        <motion.div variants={variants}>
          <HowItWorksStep index='03' title='Track Progress' subtitle='Get real-time updates, photos, and reports as your project moves forward' image={trackProgress} isReverse={false} />
        </motion.div>
      </motion.div>


    </div>
  );
}

export default HowItWorks;
